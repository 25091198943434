import GroupDTO from 'components/common/types/DTOs/GroupDTO'
import MinimalProfileDTO from 'components/common/types/DTOs/MinimalProfileDTO'
import NewTrainingDTO from 'components/common/types/DTOs/NewTrainingDTO'
import TrainingDTO from 'components/common/types/DTOs/TrainingDTO'
import NewTraining from 'components/common/types/NewTraining'
import UpdatedTrainingDTO from 'components/common/types/specific_queries/UpdatedTrainingDTO'
import Training from 'components/common/types/Training'
import { difference, intersection, isEmpty } from 'lodash'

// Converts a TrainingScheduleDTO into a TrainingSchedule which is used by AgGrid
export const convertToTraining = (training: TrainingDTO): Training => {
  return {
    ...training,
    start_date: new Date(training.start_date),
    athletes: { profiles: training.athletes, groupId: training.group?.id }
  }
}

// Converts a Training into a TrainingDTO before sending to the backend
export const convertToTrainingDTO = (training: Training): UpdatedTrainingDTO => {
  return {
    ...training,
    start_date: training.start_date.toISOString(),
    athletes: training.athletes.profiles.map(athlete => athlete.id),
    group_id: training.athletes.groupId
  }
}

// Converts a NewTraining into a NewTrainingDTO before sending to the backend
export const convertToNewTrainingDTO = (training: NewTraining, groupId: number | undefined): NewTrainingDTO => {
  const startDate: Date = training.start_date ?? new Date() // To guarantee that there is a date in the conversion
  return {
    ...training,
    start_date: startDate.toISOString(),
    athletes: training.athletes.profiles.map(athlete => athlete.id),
    group_id: groupId
  }
}

export const parseSchedules = (schedules: Training[], selectedAthleteIds: number[]): Training[] => {
  if (selectedAthleteIds.length === 0) return schedules

  return schedules.filter(schedule => intersection(selectedAthleteIds, schedule.athletes.profiles.map(athlete => athlete.id)).length > 0)
}

export const areAllAthletesInGroup = (athletes: MinimalProfileDTO[], group: GroupDTO): boolean => {
  const groupProfileIds: number[] = group.group_memberships.map(membership => membership.profile.id)
  const athleteIds: number[] = athletes.map(athlete => athlete.id)

  // If the group includes all the selected athletes, then after subtraction there will be an empty list
  return isEmpty(difference(athleteIds, groupProfileIds))
}
