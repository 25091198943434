import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react'
import I18n from 'i18n'
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor'
import { WithSession } from '../../../session/SessionProvider'
import Header from '../../../atomic/atoms/headers/Header'
import GroupMembersSelect, { GroupMember } from 'components/atomic/molecules/GroupMembersSelect'
import { useGroupsDTOQuery } from 'components/backend/Queries'
import MinimalProfileDTO from 'components/common/types/DTOs/MinimalProfileDTO'
import { isEmpty, reduce } from 'lodash'
import GroupDTO from 'components/common/types/DTOs/GroupDTO'
import { areAllAthletesInGroup } from '../Utils'

interface SessionProps {
  sessionToken: string
}

const AthletesEditor = forwardRef((props: ICellEditorParams & SessionProps, ref) => {
  const [selectedAthletes, setSelectedAthletes] = useState<MinimalProfileDTO[]>(props.value?.profiles ?? [])
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(props.value?.groupId)
  const { data: groups = [] /* refetch, isSuccess */ } = useGroupsDTOQuery()
  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // focus on the input
    searchRef.current?.focus()
  }, [])

  useEffect(() => {
    if (selectedAthletes.length > props.value.length) {
      props.stopEditing()
    }
  }, [selectedAthletes])

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue () {
        return { groupId: selectedGroupId, profiles: selectedAthletes }
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart () {
        return false
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd () {
        // Athletes are required
        return selectedAthletes === null || selectedAthletes === undefined
      }
    }
  })

  const handleSelectAthletes = (selectedGroupMembers: GroupMember[]): void => {
    setSelectedAthletes(selectedGroupMembers.map((gm) => {
      // Convert a GroupMember into a MinimalProfileDTO
      return {
        ...gm.profile,
        slug: gm.profile.public_url
      }
    }))

    setSelectedGroupId(isEmpty(selectedGroupMembers) ? undefined : selectedGroupMembers[0].groupId)
  }

  // This variable is necessary for converting the profiles into GroupMembers, the type required by GroupMembersSelect
  const groupMembers: GroupMember[] = selectedAthletes.map((athlete) => { return { profile: athlete } })
  let filteredGroups: GroupDTO[] = []
  if (selectedGroupId !== undefined) {
    const selectedGroup: GroupDTO | undefined = groups.find((group) => group.id === selectedGroupId)
    filteredGroups = selectedGroup !== undefined ? [selectedGroup] : groups
  } else { // Find the common groups of the selected athletes
    filteredGroups = reduce(groups, (commonGroups, group) => {
      if (areAllAthletesInGroup(selectedAthletes, group)) commonGroups.push(group)
      return commonGroups
    }, [] as GroupDTO[])
  }

  return (
    <div style={{ minHeight: '300px', minWidth: '300px', maxWidth: '400px' }}>
      <div className='row' style={{ marginBottom: 0 }}>
        <div className='col s12'>
          <div className='padding-wrapper'>
            <Header isTopHeader>{I18n.t('components.trainings.athletes')}</Header>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <GroupMembersSelect
            groups={filteredGroups}
            selectedMembers={groupMembers}
            setSelectedMembers={handleSelectAthletes}
            showMutualConnections={false}
          />
        </div>
      </div>
    </div>
  )
})

export default WithSession(AthletesEditor) as typeof AthletesEditor
